import React from "react";

const DeletePage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <h2
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        How To Delete Your Account and All Its Data
      </h2>
      <ol
        style={{
          listStyleType: "decimal",
          paddingLeft: "20px",
        }}
      >
        <li
          style={{
            fontSize: "18px",
            margin: "10px 0",
            color: "#555",
          }}
        >
          Log into The Particular Examen app
        </li>
        <li
          style={{
            fontSize: "18px",
            margin: "10px 0",
            color: "#555",
          }}
        >
          Tap the icon in the top right of the home screen
        </li>
        <li
          style={{
            fontSize: "18px",
            margin: "10px 0",
            color: "#555",
          }}
        >
          Tap Delete Account. This will delete your account and all its data.
        </li>
      </ol>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        Feel free to contact support at
        <br />
        <a href="mailto:support@particularexamen.com">
          support@particularexamen.com
        </a>
      </div>
    </div>
  );
};

export default DeletePage;
