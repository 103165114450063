import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div
    className="sales-page-footer"
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <Link to="/terms" style={{ textDecoration: "none", color: "black" }}>
      Terms of use
    </Link>
    <Link to="/privacy" style={{ textDecoration: "none", color: "black", padding: "10px" }}>
      Privacy Policy
    </Link>
  </div>
  )
}

export default Footer