import React from "react";
import { Button, Divider, Collapse } from "@mui/material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Logo from "../static/ExamenLogo.jpeg";
import Footer from "./Footer";

const SalesPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        className="sales-page-header"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <h1
          style={{
            padding: "10px 10px",
            marginTop: "0px",
            marginBottom: "0px",
            fontSize: "36px",
          }}
        >
          The Particular Examen
        </h1>
        <h1
          style={{
            paddingBottom: "10px",
            marginTop: "0px",
            marginBottom: "0px",
            fontSize: "24px"
          }}
        >
          Break Sinful Habits
        </h1>
        <div
          style={{
            fontSize: "18px",
            padding: "10px 10px",
          }}
        >
          Coming soon to iOS and Android!
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Feel free to contact support at
          <br />
          <a href="mailto:support@particularexamen.com">
            support@particularexamen.com
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SalesPage;
